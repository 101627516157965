<template>
  <router-view/>
</template>

<script setup lang="ts">
import { usePwaInstallEventStore } from 'stores/pwa-install-event-store';
import { checkVersion } from 'src/services/TokenService';
import { removeToken } from 'src/services/CookiesService';
import { onMounted } from 'vue';

const pwaInstallEventStore = usePwaInstallEventStore();

onMounted(() => {
  if (!checkVersion()) {
    removeToken();
  }
});

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  pwaInstallEventStore.$patch({ event: e, isInstallable: true });
});

if (window.matchMedia('(display-mode: standalone)').matches) {
  pwaInstallEventStore.$patch({ isInstallable: false });
}
</script>